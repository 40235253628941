<template>
  <div class="report-wrap" :class="getSearchCondition ? 'report-grid' : ''">
    <search-report v-if="getSearchCondition"></search-report>
    <report-detail v-if="route.fullPath.includes('detail')"></report-detail>
    <report-list v-else></report-list>
  </div>
</template>
<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'report',
  components: {
    ReportList: defineAsyncComponent(() => import('@/components/report/ReportList.vue')),
    SearchReport: defineAsyncComponent(() => import('@/components/report/SearchReport.vue')),
    ReportDetail: defineAsyncComponent(() => import('@/components/report/ReportDetail.vue')),
  },
  setup() {
    const route = useRoute();
    const getSearchCondition = useGetters('report', ['getSearchCondition']);
    const doGetStoreList = useActions('common', ['doGetStoreList']);
    doGetStoreList();
    //離開清空vuex
    const RESET = useMutations('report', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/order/report')) RESET();
      }
    );
    return { route, getSearchCondition };
  },
};
</script>
<style lang="scss" scoped>
.report-wrap {
  width: 100%;
  height: 100%;
}
.report-grid {
  display: grid;
  grid-template-rows: 96px auto;
  grid-gap: 16px;
}
</style>
